import {
    AddCoApplicantForPrequalificationResponse,
    AddCoApplicantResponse,
    CreateWarrantyPrequalificationResponse,
    EditWarrantyApplicantionResponse,
    GetApplicantsResponse,
    GetBranchOffice,
    GetCompanyAgreementsResponse,
    GetParticipantsPersonalAndJobDataCheckedResponse,
    GetPropertyResponse,
    GetProvincesAndCitiesResponse,
    GetRealEstateResponse,
    GetRequestDetailsResponse,
    GetRequestHistoryResponse,
    GetWarrantyPaymentDetailsResponse,
    GetWarrantyPaymentOptionsDetailsResponse,
    InformationOfPaymentMethodResponse,
    LoginResponse,
    MercadoPagoCyberWeekDiscountResponse,
    MercadoPagoPaymentMethodResponse,
    MercadoPagoPreferenceMethodResponse,
    NewRealStateResponse,
    NewReclaimResponse,
    NewWarrantyApplicationResponse,
    SaveDocumentsResponse,
    SendQuestionsResponse,
    SendRegretResponse,
    SettlePaymentMethodResponse
} from "./responses/responses";
import {LeadResponse} from "./responses/lead";
import {swapKeysAndValues} from "../lib/others";
import {getSetting} from "../settings/settings";
import {InsuranceInformationResponse} from "./responses/InsuranceInformationResponse";
import {CalculateRentalCostsResponse} from "./responses/CalculateRentalCostsResponse";


export class ApiClient {
    constructor(api) {
        this.api = api;
    }

    getBranchOffices() {
        return this.api.getBranchOffices();
    }

    getRealEstate(current_page, name = '', city = '' , province = '', details = true, unpublished = false, onLoadData = undefined) {
        const requestData = {
            'incluir_detalle': details,
            'no_publicadas': unpublished,
            'pagina' : current_page,
            'nombre' : name,
            'localidad' : city,
            'provincia' : province
        };

        return this.api.getRealEstate(requestData).then(result => {
            let response = new GetRealEstateResponse(result);
            if (onLoadData) onLoadData(response);
        });
    }

    getAllRealEstate(onLoadData = undefined) {
        return this.api.getAllRealEstate().then(result => {
            let response = new GetRealEstateResponse(result);
            if (onLoadData) onLoadData(response);
        });
    }

    getProvincesAndCities(onLoadData = undefined) {
        return this.api.getProvincesAndCities().then(result => {
            let response = new GetProvincesAndCitiesResponse(result);
            if (onLoadData) onLoadData(response);
        });
    }

    getCompanyAgreements(onLoadData = undefined) {
        return this.api.getCompanyAgreements().then(result => {
            let response = new GetCompanyAgreementsResponse(result);
            if (onLoadData) onLoadData(response);
        });
    }

    newReclaim(reclaimData, onResponse = undefined) {
        let requestData = {
            propietario: {
                nombre: reclaimData.firstName,
                apellido: reclaimData.lastName,
                num_doc: reclaimData.idNumber,
                telefono_codigo: reclaimData.phoneAreaCode,
                telefono_numero: reclaimData.phoneNumber,
                celular_codigo: reclaimData.celAreaCode,
                celular_numero: reclaimData.celNumber,
                email_1: reclaimData.email,
                email_2: reclaimData.email2,
            },
            inquilino: {
                nombre: reclaimData.renterFirstName,
                apellido: reclaimData.renterLastName,
                dni: reclaimData.renterIdNumber,
            },
            incumplimiento: {
                domicilio_propiedad_calle: reclaimData.addressStreet,
                domicilio_propiedad_numero: reclaimData.addressNumber,
                domicilio_propiedad_piso: reclaimData.addressFloor,
                domicilio_propiedad_departamento: reclaimData.addressApartment,
                provincia: reclaimData.province,
                localidad: reclaimData.city,
                fecha_firma_locacion: reclaimData.signedOn,
                nro_cert_garantia: reclaimData.applicationNumber,
                observaciones: reclaimData.observations,
            },
        };

        return this.api.newReclaim(requestData).then(result => {
            let response = new NewReclaimResponse(result);
            if (onResponse) onResponse(response);
        });
    }

    newRealState(realStateData, onResponse = undefined) {
        let requestData = {
            nombre: realStateData.legalName,
            domicilio: realStateData.legalAddress,
            codigo_area_telefono: realStateData.phoneAreaCode,
            telefono: realStateData.phoneNumber,
            email1: realStateData.email1,
            email2: realStateData.email2,
            sitio_web: realStateData.website,
            responsable: realStateData.manager,
            logo: realStateData.logo,
        };

        return this.api.newRealState(requestData).then(result => {
            let response = new NewRealStateResponse(result);
            if (onResponse) onResponse(response);
        });
    }

    newWarrantyApplication(applicationData, onResponse = undefined) {
        let requestData = {
            nombre: applicationData.name,
            apellido: applicationData.surname,
            email: applicationData.email,
            email2: applicationData.email2,
            como_nos_conocio: applicationData.poll,
            celular_codigo: applicationData.phoneAreaCode,
            celular_numero: applicationData.phoneNumber,
            tipo_doc: applicationData.idType,
            num_doc: applicationData.idNumber,
            id_inmobiliaria: applicationData.realEstate,
            nueva_inmobiliaria: applicationData.newRealEstate,
            zona_de_alquiler: applicationData.city,
            creada_en: applicationData.source,
        };

        return this.api.newWarrantyApplication(requestData).then(result => {
            let typeOfResponse = NewWarrantyApplicationResponse.from(result);
            let response = new typeOfResponse(result);
            if (onResponse) onResponse(response);
        });
    }

    getLeadFromPrequalification(prequalificationData, onResponse = undefined) {
        let requestData = {
            'precalificacion_id': prequalificationData.warrantyPrequalificationId,
        }
        return this.api.getLeadFromPrequalification(requestData).then(result => {
            let response = LeadResponse.newFrom(result);
            if (onResponse) onResponse(response);
        });
    }

    newLead(leadData, onResponse = undefined) {
        let requestData = this.leadRequestDataFrom(leadData);

        return this.api.newLead(requestData).then(result => {
            let response = LeadResponse.newFrom(result);
            if (onResponse) onResponse(response);
        });
    }

    newWarrantyFromLead(warrantyLead, onResponse = undefined) {
        let requestData = {id_prospecto: warrantyLead.id}

        return this.api.newWarrantyFromLead(requestData).then(result => {
            let typeOfResponse = NewWarrantyApplicationResponse.from(result);
            let response = new typeOfResponse(result);
            if (onResponse) onResponse(response);
        });
    }

    editLead(leadData, onResponse = undefined) {
        let requestData = this.leadRequestDataFrom(leadData);

        return this.api.editLead(leadData.id, requestData).then(result => {
            let response = LeadResponse.newFrom(result);
            if (onResponse) onResponse(response);
        });
    }

    leadRequestDataFrom(leadData) {
        return {
            nombre: leadData.name,
            apellido: leadData.surname,
            email: leadData.email,
            email2: leadData.email2,
            como_nos_conocio: leadData.poll,
            celular_codigo: leadData.phoneAreaCode,
            celular_numero: leadData.phoneNumber,
            tipo_doc: leadData.idType,
            num_doc: leadData.idNumber,
            id_inmobiliaria: leadData.realEstate,
            nueva_inmobiliaria: leadData.newRealEstate,
            zona_de_alquiler: leadData.city,
            origen: leadData.source,
        };
    }

    calculateRentalCosts(rentalData, onResponse = undefined) {
        let requestData = {
            alquiler: rentalData.averageRentalValue,
            expensas: rentalData.averageExpensesValue,
            duracion_contrato: rentalData.contractPeriod,
            tipo_garantia: !!rentalData.isRenewal,
        };

        return this.api.calculateRentalCosts(requestData).then(result => {
            let response = new CalculateRentalCostsResponse(result);
            if (onResponse) onResponse(response);
        });
    }

    requestEmailShipping(dataForEmailShipping, onResponse=undefined){
        let requestData = {
            email: dataForEmailShipping.email,
            alquiler: dataForEmailShipping.averageRentalValue,
            expensas: dataForEmailShipping.averageExpensesValue,
            duracion_contrato: dataForEmailShipping.contractPeriod,
            es_renovacion: !!dataForEmailShipping.isRenewal,
        }
        return this.api.requestEmailShipping(requestData)
    }

    sendQuestions(contactData, onResponse = undefined) {
        let requestData = {
            nombre_completo: contactData.fullName,
            email: contactData.email,
            telefono: contactData.phoneNumber,
            consulta: contactData.question,
            email_a: contactData.emailTo,
        };

        return this.api.sendQuestion(requestData).then(result => {
            let response = new SendQuestionsResponse(result);
            if (onResponse) onResponse(response);
        });
    }

    login(requestData, onResponse = undefined) {
        let apiRequestData = {
            tipo_doc: requestData.docType,
            num_doc: requestData.docNumber,
            codigo_solicitud: requestData.requestCode,
        };

        return this.api.login(apiRequestData).then(result => {
            let response = new LoginResponse(result);
            if (onResponse) onResponse(response);
        });
    }

    getRequestDetails(requestCode, onResponse = undefined) {
        return this.api.getRequestDetails(requestCode).then(result => {
            let response = new GetRequestDetailsResponse(result);
            if (onResponse) onResponse(response);
        });
    }

    getParticipantsData(warrantyApplicationNumber, onResponse = undefined) {
        return this.api.getParticipantsData(warrantyApplicationNumber).then(result => {
            let response = new GetApplicantsResponse(result);
            if (onResponse) onResponse(response);
        });
    }

    getParticipantsPersonalAndJobDataChecked(warrantyApplicationNumber, onResponse = undefined) {
        return this.api.getParticipantsPersonalAndJobDataChecked(warrantyApplicationNumber).then(result => {
            let response = new GetParticipantsPersonalAndJobDataCheckedResponse(result);
            if (onResponse) onResponse(response);
        })
    }

    getFormRequestInitialData(warrantyApplicationNumber, onResponse = undefined) {
        const getProvincesAndCitiesRequest = this.api.getProvincesAndCities();
        const getRequestDetailsRequest = this.api.getRequestDetails(warrantyApplicationNumber);
        const getParticipantDataRequest = this.api.getParticipantsData(warrantyApplicationNumber);
        const getPropertyDataRequest = this.api.getPropertyData(warrantyApplicationNumber);
        const getRealEstateRequest = this.api.getAllRealEstate();
        const getCompanyAgreementsRequest = this.api.getCompanyAgreements();
        const getParticipantsPersonalAndJobDataCheckedRequest =
            this.api.getParticipantsPersonalAndJobDataChecked(warrantyApplicationNumber);

        let allData = {};
        Promise.all([
            getProvincesAndCitiesRequest,
            getRequestDetailsRequest,
            getParticipantDataRequest,
            getPropertyDataRequest,
            getRealEstateRequest,
            getCompanyAgreementsRequest,
            getParticipantsPersonalAndJobDataCheckedRequest]
        ).then(function (results) {
            allData["regions"] = new GetProvincesAndCitiesResponse(results[0]);
            allData["details"] = new GetRequestDetailsResponse(results[1]);
            allData["participants"] = new GetApplicantsResponse(results[2]);
            allData["property"] = new GetPropertyResponse(results[3]);
            allData["realEstate"] = new GetRealEstateResponse(results[4]);
            allData["companyAgreements"] = new GetCompanyAgreementsResponse(results[5]);
            allData["participantsDataChecked"] = new GetParticipantsPersonalAndJobDataCheckedResponse(results[6]);
            if (onResponse) onResponse(allData);
        });
    }

    getInitRequestNeededData(onResponse) {
        const getProvincesAndCitiesRequest = this.api.getProvincesAndCities();
        const getRealEstateRequest = this.api.getAllRealEstate();

        let allData = {};
        Promise.all([getProvincesAndCitiesRequest, getRealEstateRequest]).then(function(results){
            allData["regions"] = new GetProvincesAndCitiesResponse(results[0]);
            allData["realEstate"] = new GetRealEstateResponse(results[1]);
            onResponse(allData);
        });
    }

    editProperty(warrantyApplicationNumber, fields, onResponse = undefined) {
        const fieldsMapping = {
            'addressStreet': 'domicilio_inmueble_calle',
            'addressNumber': 'domicilio_inmueble_numero',
            'addressFloor': 'domicilio_inmueble_piso',
            'addressApartment': 'domicilio_inmueble_departamento',
            'addressCity': 'domicilio_inmueble_localidad',
            'addressProvince': 'domicilio_inmueble_provincia',
            'rentalType': 'tipo_de_alquiler',
            'rentalLength': 'duracion_alquiler',
            'rentalPeriod': 'periodo_de_alquiler',
            'amountPeriod1': 'alquiler_pactado_periodo_1',
            'amountPeriod2': 'alquiler_pactado_periodo_2',
            'amountPeriod3': 'alquiler_pactado_periodo_3',
            'amountPeriod4': 'alquiler_pactado_periodo_4',
            'amountPeriod5': 'alquiler_pactado_periodo_5',
            'amountPeriod6': 'alquiler_pactado_periodo_6',
            'amountPeriod7': 'alquiler_pactado_periodo_7',
            'amountPeriod8': 'alquiler_pactado_periodo_8',
            'amountPeriod9': 'alquiler_pactado_periodo_9',
            'expenses': 'expensas_pactadas',
            'realState': 'inmobiliaria_id',
            'isContractExtension': 'es_prorroga_o_renovacion',
        };

        let requestData = this.mapFieldsWith(fieldsMapping, fields);

        return this.api.editProperty(warrantyApplicationNumber, requestData).then(result => {
            let typeOfResponse = new EditWarrantyApplicantionResponse.from(result);
            let response = new typeOfResponse(result, swapKeysAndValues(fieldsMapping));
            if (onResponse) onResponse(response);
        });
    }

    editCompanyAgreement(warrantyApplicationNumber, fields, onResponse = undefined) {
        const fieldsMapping = {
            'companyAgreement': 'convenio',
        };

        let requestData = this.mapFieldsWith(fieldsMapping, fields);

        return this.api.editCompanyAgreement(warrantyApplicationNumber, requestData).then(result => {
            let typeOfResponse = new EditWarrantyApplicantionResponse.from(result);
            let response = new typeOfResponse(result, swapKeysAndValues(fieldsMapping));
            if (onResponse) onResponse(response);
        });
    }

    editParticipant(warrantyApplicationNumber, fields, type, id, onResponse = undefined) {
        const fieldsMapping = {
            firstName: 'nombre',
            lastName: 'apellido',
            email: 'email',
            docType: 'tipo_doc',
            docNumber: 'num_doc',
            cellPhoneAreaCode: 'celular_codigo',
            cellPhoneNumber: 'celular_numero',
            birthdate: 'fecha_nacimiento',
            sex: 'sexo',
            cuit: 'cuit',
            addressStreet: 'domicilio_calle',
            addressStreetNumber: 'domicilio_numero',
            addressStreetFloor: 'domicilio_piso',
            addressStreetApartment: 'domicilio_departamento',
            addressProvince: 'domicilio_provincia',
            addressCity: 'domicilio_ciudad',
            employeeType: 'empleado_tipo',
            currentCompany: 'empresa_ocu_actual',
            currentCompanyCuit: 'cuit_empresa_ocu_actual',
            jobAddressStreet: 'domicilio_calle_ocu_actual',
            jobAddressStreetNumber: 'domicilio_numero_ocu_actual',
            jobAddressStreetFloor: 'domicilio_piso_ocu_actual',
            jobAddressStreetApartment: 'domicilio_departamento_ocu_actual',
            jobLocality: 'localidad_ocu_actual',
            jobAddressProvince: 'domicilio_provincia_ocu_actual',
            jobAddressCity: 'domicilio_ciudad_ocu_actual',
            jobPhoneAreaCode: 'telefono_codigo_ocu_actual',
            jobPhoneNumber: 'telefono_numero_ocu_actual',
            seniority: 'antiguedad_ocu_actual',
            income: 'ingresos_monto',
            liveInTheProperty: 'ocupa_inmueble',
        };


        let requestData = this.mapFieldsWith(fieldsMapping, fields);
        requestData['id'] = id;

        let apiEndpoint = this.api.editApplicant;
        if (type !== 'applicants') {
            apiEndpoint = this.api.editCoApplicant;
        }

        return apiEndpoint(warrantyApplicationNumber, requestData).then(result => {
            let typeOfResponse = EditWarrantyApplicantionResponse.from(result);
            let response = new typeOfResponse(result, swapKeysAndValues(fieldsMapping));
            if (onResponse) onResponse(response);
        });
    }

    addParticipantDocuments(warrantyApplicationNumber, type, id, files, onResponse = undefined) {
        let apiEndpoint = this.api.addApplicantDocuments;
        if (type !== 'applicants') {
            apiEndpoint = this.api.addCoApplicantDocuments;
        }

        return apiEndpoint(warrantyApplicationNumber, id, files).then((result, error) => {
            let response = new SaveDocumentsResponse(result);
            if (onResponse) onResponse(response);
        });
    }

    addCoApplicant(warrantyApplicationNumber, onResponse) {
        return this.api.addCoApplicant(warrantyApplicationNumber).then((result, error) => {
            let response = new AddCoApplicantResponse(result);
            if (onResponse) onResponse(response);
        });
    }

    getRequestHistory(requestCode, onResponse = undefined) {
        return this.api.getRequestHistory(requestCode).then(result => {
            let response = new GetRequestHistoryResponse(result);
            if (onResponse) onResponse(response);
        });
    }

    getPrequalificationResult(leadId, warrantyPrequalification, onResponse) {
        setTimeout(() => {
            let requestData = {
                prospecto_id: leadId,
                sexo: warrantyPrequalification.sex.value,
                ingresos: warrantyPrequalification.income.value,
                condicion_laboral: warrantyPrequalification.employeeType.value,
                antiguedad_laboral: warrantyPrequalification.seniority.value,
                valor_alquiler: warrantyPrequalification.amountFirstPeriod.value,
                expensas: warrantyPrequalification.expenses.value,
            };

            return this.api.createWarrantyPrequalification(requestData).then(result => {
                let typeOfResponse = CreateWarrantyPrequalificationResponse.from(result);
                let response = new typeOfResponse(result);
                if (onResponse) onResponse(response);
            });
        }, 4000);
    }

    getPrequalificationWithCoApplicantResult(warrantyPrequalificationNumber, coApplicant, onResponse) {
        setTimeout(() => {
            let requestData = {
                nombre: coApplicant.name.value,
                apellido: coApplicant.surname.value,
                sexo: coApplicant.sex.value,
                tipo_doc: coApplicant.idType.value,
                num_doc: coApplicant.idNumber.value,
                ingresos: coApplicant.income.value,
                condicion_laboral: coApplicant.employeeType.value,
                antiguedad_laboral: coApplicant.seniority.value,
            };

            return this.api.addCoApplicantForPrequalification(requestData, warrantyPrequalificationNumber).then(result => {
               let typeOfResponse = AddCoApplicantForPrequalificationResponse.from(result);
               let response = new typeOfResponse(result);
               if (onResponse) onResponse(response);
            });
        }, 4000);
    }

    getRenewal(warrantyApplicationNumber){
        let requestData = {nro_solicitud: warrantyApplicationNumber};
        return this.api.getRenewal(requestData).then(result => {
            let response = new GetBranchOffice(result);
            return response
        })
    }

    sendEmailConfirmation(email, participantId, warrantyApplicationNumber) {
        let requestData = {
            email: email,
            id_participante: participantId,
        };
        return this.api.sendEmailConfirmation(warrantyApplicationNumber, requestData).then(result => {
            return result
        })
    }

    sendEmailInsuranceInformationRequested(warrantyApplicationNumber) {
        let requestData = {nro_solicitud: warrantyApplicationNumber};
        return this.api.sendEmailInsuranceInformationRequested(requestData).then(result => {
            return new InsuranceInformationResponse(result)
        })
    }

    mapFieldsWith(mapping, fields) {
        let mappedFields = {};
        for(let fieldName in fields) {
            const mappedFieldName = mapping[fieldName];
            mappedFields[mappedFieldName] = fields[fieldName];
        }

        return mappedFields;
    }

    sendRegret(regretData, onResponse = undefined) {
        let requestData = {
            tipo_doc: regretData.idType,
            num_doc: regretData.idNumber,
            email: regretData.email,
            num_telefono: regretData.phoneNumber,
            nro_solicitud: regretData.warrantyNumber,
            nombre: regretData.name,
            apellido: regretData.lastname
        };
        return this.api.sendRegret(requestData).then(result => {
            let response = new SendRegretResponse(result);
            if (onResponse) onResponse(response);
        });
    }

    getWarrantyPaymentDetails(requestCode, onResponse = undefined) {
        return this.api.getWarrantyPaymentDetails(requestCode).then(result => {
            let response = new GetWarrantyPaymentDetailsResponse(result);
            if (onResponse) onResponse(response);
        });
    }

    getWarrantyPaymentOptionsDetails(requestCode, onResponse = undefined) {
        return this.api.getWarrantyPaymentOptionsDetails(requestCode).then(result => {
            let response = new GetWarrantyPaymentOptionsDetailsResponse(result);
            if (onResponse) onResponse(response);
        });
    }

    settlePaymentMode(paymentData, onResponse = undefined){
        return this.api.settlePaymentMode(paymentData).then(result => {
            let response = new SettlePaymentMethodResponse(result);
            if (onResponse) onResponse(response);
        });
    }

    getInformationForNewWarrantyPayment(paymentData, onResponse = undefined){
        return this.api.getInformationForNewWarrantyPayment(paymentData).then(result => {
            let response = new InformationOfPaymentMethodResponse(result);
            if (onResponse) onResponse(response);
        });
    }

    getMercadoPagoPreference(warrantyNumber, amountToPay, onResponse = undefined) {
        const url = getSetting('WEB_URL');
        const backUrls = {
            "success": `${url}dashboard`,
            "failure": `${url}dashboard`,
            "pending": `${url}dashboard`
        };
        const requestData = {
            'back_urls': backUrls,
            'medio_de_pago': 'BILLETERA',
            "monto_final": amountToPay.toString(),
        }
        return this.api.getMercadoPagoPreference(warrantyNumber, requestData).then(result => {
            let response = new MercadoPagoPreferenceMethodResponse(result);
            if (onResponse) onResponse(response);
        });
    }

    settleMercadoPagoPayment(warrantyNumber, card, installment, nid, onResponse) {
        const requestData = {
            "tarjeta": {
                "codigo": card.token,
                "emisor": card.issuerId
            },
            "titular": {
                "tipo_doc": nid.type,
                "num_doc": nid.number
            },
            'medio_de_pago': 'TARJETA',
            "cantidad_de_cuotas": installment.amountOfInstallments(),
            "monto_final": installment.originalTotalFee().toString(),
            "tasa_de_interes_del_plan": installment.installmentRate().toString(),
        }
        return this.api.settleMercadoPagoPayment(warrantyNumber, requestData).then(result => {
            let response = new MercadoPagoPaymentMethodResponse(result);
            if (onResponse) onResponse(response);
        });
    }

    mercadoPagoCyberWeekDiscount(onResponse) {
        return this.api.mercadoPagoCyberWeekDiscount().then(result => {
            let response = new MercadoPagoCyberWeekDiscountResponse(result);
            if (onResponse) onResponse(response);
        });
    }
}
