import React, {Component} from "react";
import Cookies from 'universal-cookie';
import "../styles/App.scss";
import Form from "../components/request-form/Form";
import {isMobile} from "react-device-detect";
import {app} from "../app/app";
import {Loader} from "../components/elements/Loader";
import {DocumentsForm, DocumentsFormProgressCalculator, ParticipantForm} from "../models/Form";
import {CompanyAgreementForm, FormProgressCalculator, PropertyForm} from "../models/Form";
import {registerGoogleTagManagerEvent} from "../lib/googleTagManager";


export default class RequestForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            screenWidth: window.innerWidth,
            loading: true,
            saving: false,
            applicants: {},
            coApplicants: {},
            companyAgreement: '',
            property: {},
            regionsData: undefined,
            realEstate: undefined,
            applicantPersonalDataChecked: true,
            applicantJobDataChecked: true,
            coApplicantsDataChecked: {}
        };

        this.forms = {
            applicants: {},
            coApplicants: {},
            property: undefined,
            companyAgreement: undefined
        };

        this.cookies = new Cookies();

        this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
        this.onLoadInitialData = this.onLoadInitialData.bind(this);
        this.setInitialParticipantData = this.setInitialParticipantData.bind(this);
        this.initializeParticipantForms = this.initializeParticipantForms.bind(this);
        this.onSaveNewParticipantData = this.onSaveNewParticipantData.bind(this);
        this.onSaveCompanyAgreement = this.onSaveCompanyAgreement.bind(this);
        this.updateParticipantData = this.updateParticipantData.bind(this);
        this.updateCompanyAgreement = this.updateCompanyAgreement.bind(this);
        this.updatePropertyData = this.updatePropertyData.bind(this);
        this.updatePropertyFormProgress = this.updatePropertyFormProgress.bind(this);
        this.updatePartipantFormProgress = this.updatePartipantFormProgress.bind(this);
        this.setFieldsErrorsInParticipantForm = this.setFieldsErrorsInParticipantForm.bind(this);
        this.setFieldErrorsInPropertyForm = this.setFieldErrorsInPropertyForm.bind(this);
        this.onCreateCoApplicant = this.onCreateCoApplicant.bind(this);
        this.addCoApplicant = this.addCoApplicant.bind(this);
        this.propertyForm = this.propertyForm.bind(this);
        this.emailConfirmation = this.emailConfirmation.bind(this);
    }

    _warrantyApplicationNumber() {
        return app.loggedInUser().warrantyApplicationNumber();
    }

    handleWindowSizeChange() {
        this.setState({screenWidth: window.innerWidth});
    }

    handleBeforeUnload(event) {
        event.preventDefault();
        if(this.state.saving) {
            const stillSavingMessage = 'Tu información aún se está guardando. ¿Estás seguro de que deseas salir?';
            event.returnValue = stillSavingMessage;
            return stillSavingMessage;
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleWindowSizeChange);
        window.addEventListener("beforeunload", this.handleBeforeUnload.bind(this));
        app.apiClient().getFormRequestInitialData(this._warrantyApplicationNumber(), this.onLoadInitialData);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
        window.removeEventListener("beforeunload", this.handleBeforeUnload);
    }

    setInitialParticipantData(participantType, participantId, callback = undefined) {
        let participants = this.state[participantType];
        const participantForm = this.forms[participantType][participantId][ParticipantForm.typeOfData()];
        const documentsForm = this.forms[participantType][participantId][DocumentsForm.typeOfData()];
        const participantData = participantForm.fieldsByName();
        const documentsData = documentsForm.fieldsByName();

        participants[participantId] = {
            participantData: participantData,
            documentsData: documentsData,
            controlledDocuments: documentsForm.controlledDocuments(),
            progress: {
                participantData: this.formProgressCalculatorFor(participantForm, participantData).progress(),
                documentsData: this.formProgressCalculatorFor(documentsForm, participantData).progress()
            }
        };

        this.setState({[participantType]: participants}, () => {
            if (callback) return callback();
        });
    }

    initializeParticipantForms(participantType, initialData) {
        let participantForm = new ParticipantForm(participantType, initialData['id'], this.state.regionsData);
        let documentsForm = new DocumentsForm(participantType, initialData['id'], initialData['controlledDocuments']);
        this.forms[participantType][initialData['id']] = {};
        this.forms[participantType][initialData['id']][participantForm.identifier()] = participantForm;
        this.forms[participantType][initialData['id']][documentsForm.identifier()] = documentsForm;

        participantForm.setInitialData(initialData);
        documentsForm.setInitialData(initialData);
    }

    onLoadInitialData(responses) {
        if (responses['participants'].hasErrors() || responses['regions'].hasErrors()) {
            const errorMessage = "Ocurrió un error al cargar los datos. Por favor inténtelo nuevamente más tarde";
            this.setState({errorMessage: errorMessage, loading: false});
            return;
        }
        this.setState({
            regionsData: responses['regions'],
            office: responses['details'].requestDetails()['office'],
            applicationNumber: responses['details'].requestDetails()['number'],
            companyAgreement: responses['details'].requestDetails()['companyAgreement'],
            realEstate: responses['realEstate'].realEstate(),
            companyAgreements: responses['companyAgreements'].companyAgreements(),
            applicantPersonalDataChecked: responses['participantsDataChecked'].applicant()['personalDataChecked'],
            applicantJobDataChecked: responses['participantsDataChecked'].applicant()['jobDataChecked'],
            coApplicantsDataChecked: responses['participantsDataChecked'].coApplicants()
        });
        responses['participants'].coApplicants().forEach(coApplicant => {
            this.initializeParticipantForms('coApplicants', coApplicant);
            this.setInitialParticipantData('coApplicants', coApplicant['id']);
        });

        this.initializeParticipantForms('applicants', responses['participants'].applicant());
        this.setInitialParticipantData('applicants', responses['participants'].applicant()['id']);

        this.initializePropertyForm(responses['property'].propertyData());
        this.initializeCompanyAgreementForm();
        this.setInitialPropertyData();

        this.setState({loading: false});
    }

    initializePropertyForm(initialData) {
        const propertyForm = new PropertyForm('property', this.state.regionsData, this.state.realEstate);
        this.forms['property'] = propertyForm;
        propertyForm.setInitialData(initialData);
    }

    initializeCompanyAgreementForm() {
        const companyAgreementForm = new CompanyAgreementForm('companyAgreement');
        this.forms['companyAgreement'] = companyAgreementForm;
        companyAgreementForm.setInitialData({companyAgreement: this.state.companyAgreement});
    }

    setInitialPropertyData() {
        const propertyForm = this.forms['property'];

        this.setState({property: {
            data: propertyForm.fieldsByName(),
            progress: new FormProgressCalculator(propertyForm).progress()
        }});
    }

    formOf(participantType, participantId, fieldName) {
        const formsOfParticipant = this.forms[participantType][participantId];
        for(let formName in formsOfParticipant) {
            const form = formsOfParticipant[formName];
            if(form.hasFieldNamed(fieldName)) {
                return form;
            }
        }

        throw new Error("No form for field " + fieldName);
    }

    propertyForm() {
        return this.forms['property'];
    }

    onSaveNewParticipantData(form, response) {
        this.setState({saving: false});
        if (!response.hasErrors()) {
            this.updatePartipantFormProgress(form);
            return;
        }

        if (response.errorCode() === 1) {
            console.log("No existe la solicitud");
        }

        if (response.errorCode() === 2) {
            this.setFieldsErrorsInParticipantForm(form, response.fieldErrors());
        }
    }

    onSaveNewPropertyData(form, response) {
        this.setState({saving: false});
        if (!response.hasErrors()) {
            this.updatePropertyFormProgress(form);
            this.updateOffice();
            return;
        }

        if (response.errorCode() === 1) {
            console.log("No existe la solicitud");
        }

        if (response.errorCode() === 2) {
            this.setFieldErrorsInPropertyForm(form, response.fieldErrors());
        }
    }

    onSaveCompanyAgreement(form, response) {
        this.setState({saving: false});
        if (!response.hasErrors()) {
            return;
        }

        if (response.errorCode() === 1) {
            console.log("No existe la solicitud");
        }
    }

    updatePropertyData(fieldsToUpdate) {
        let form = this.propertyForm();
        for(let fieldName in fieldsToUpdate) {
            const value = fieldsToUpdate[fieldName];
            form.updateFieldValue(fieldName, value);
        }

        let propertyData = this.state.property;
        propertyData.data = form.fieldsByName();

        this.setState({'property': propertyData, saving: true}, () => {
            form.save((response) => this.onSaveNewPropertyData(form, response));
        });
    }

    emailConfirmation(participantType, participantId) {
        let form = this.formOf(participantType, participantId, 'email');
        let warranty = this._warrantyApplicationNumber();

        let email = form.valueOfFieldNamed('email');
        let response = app.apiClient().sendEmailConfirmation(email, participantId, warranty);

        return response
    }

    updateParticipantData(participantType, participantId, dataType, fieldName, value) {
        let form = this.formOf(participantType, participantId, fieldName);

        form.updateFieldValue(fieldName, value);

        let participants = this.state[participantType];
        let participantData = participants[participantId];
        participantData[form.typeOfParticipant()] = form.fieldsByName();
        participants[participantId] = participantData;

        this.setState({[participantType]: participants, saving: true}, () => {
            form.save((response) => this.onSaveNewParticipantData(form, response));
        });
    }

    updateCompanyAgreement(fieldName, value) {
        let form = this.forms['companyAgreement'];

        form.updateFieldValue(fieldName, value);

        this.setState({companyAgreement: value, saving: true}, () => {
            form.save((response) => this.onSaveCompanyAgreement(form, response));
        });
    }

    updatePartipantFormProgress(form) {
        let typeOfForm = form.identifier();
        let typeOfParticipant = form.typeOfParticipant();

        let participants = this.state[typeOfParticipant];
        let participantData = participants[form.participantId()];
        let currentProgress = participantData['progress'];

        currentProgress[typeOfForm] = this.formProgressCalculatorFor(form, participantData['participantData']).progress();
        participantData['progress'] = currentProgress;
        participants[form.participantId()] = participantData;

        this.setState({[typeOfParticipant]: participants});
        this.notifyWhenFormIsComplete();
    }

    formProgressCalculatorFor(form, participantData) {
        if(form.identifier() === DocumentsForm.typeOfData()) {
            return new DocumentsFormProgressCalculator(form, participantData['employeeType'].value())
        }
        return new FormProgressCalculator(form);
    }

    updatePropertyFormProgress(form) {
        let propertyData = this.state.property;
        propertyData.progress = new FormProgressCalculator(form).progress();

        this.setState({property: propertyData});
        this.notifyWhenFormIsComplete();
    }

    updateOffice(){
        let warranty = this._warrantyApplicationNumber();
        app.apiClient().getRequestDetails(warranty, (response) => { this.setState({office: response.requestDetails()['office']})});
    }

    setFieldErrorsInPropertyForm(form, errors) {
        for(let fieldName in errors) {
            const error = errors[fieldName];
            const field = form.fieldNamed(fieldName);

            field.addError(error);
        }

        let propertyData = this.state.property;
        propertyData.data = form.fieldsByName();

        this.setState({property: propertyData});
    }

    setFieldsErrorsInParticipantForm(form, errors) {
        const typeOfParticipant = form.typeOfParticipant();

        for(let fieldName in errors) {
            const error = errors[fieldName];
            const field = form.fieldNamed(fieldName);

            field.addError(error);
        }

        let participants = this.state[typeOfParticipant];
        let participantData = participants[form.participantId()];
        participantData[form.typeOfParticipant()] = form.fieldsByName();
        participants[form.participantId()] = participantData;

        this.setState({[typeOfParticipant]: participants});
    }

    onCreateCoApplicant(response, onNewCoApplicantCreated) {
        if (response.hasErrors()) {
            alert("Ocurrió un error al crear el nuevo cosolicitante. Inténtelo más tarde nuevamente.");
            return;
        }

        this.initializeParticipantForms('coApplicants', {'id': response.newCoApplicantId()});
        this.setInitialParticipantData('coApplicants', response.newCoApplicantId(), onNewCoApplicantCreated);
    }

    addCoApplicant(onNewCoApplicantCreated) {
        const warrantyApplicationNumber = this._warrantyApplicationNumber();
        app.apiClient().addCoApplicant(
            warrantyApplicationNumber,
            (response) => this.onCreateCoApplicant(response, onNewCoApplicantCreated)
        );
    }

    notifyWhenFormIsComplete() {
        if(this.totalProgress() > 70 && !this.alreadyNotified()) {
            this.notifyFormCompleted()
        }
    }

    notifyFormCompleted() {
        registerGoogleTagManagerEvent({'event': 'Completo70porciento', 'event_category': 'Finaer', 'event_label': ''});
        this.cookies.set('applicationCompleted', true, {path: '/', maxAge:  365*24*60*60});
    }

    alreadyNotified() {
        return this.cookies.get('applicationCompleted');
    }

    totalProgress() {
        let formsByParticipant = {};
        let progress = [];
        Object.assign(formsByParticipant, this.state['applicants']);
        Object.assign(formsByParticipant, this.state['coApplicants']);

        for(let participantId in formsByParticipant) {
            progress.push(formsByParticipant[participantId]['progress'][ParticipantForm.typeOfData()]);
        }

        progress.push(this.state['property']['progress']);

        const completed = progress.reduce((acum, current) => { return acum + current });
        const total = progress.length;
        return (completed / total);
    }

    renderContent() {
        const width = this.state.screenWidth;
        const deviceIsMobile = width <= 1200 || isMobile;

        return <Form isMobile={deviceIsMobile}
                     applicants={this.state.applicants}
                     coApplicants={this.state.coApplicants}
                     property={this.state.property}
                     requestOffice={this.state.office}
                     applicationNumber={this.state.applicationNumber}
                     realEstate={this.state.realEstate}
                     companyAgreement={this.state.companyAgreement}
                     companyAgreements={this.state.companyAgreements}
                     updateParticipantData={this.updateParticipantData}
                     updatePropertyData={this.updatePropertyData}
                     updateCompanyAgreementData={this.updateCompanyAgreement}
                     addCoApplicant={this.addCoApplicant}
                     applicantPersonalDataChecked={this.state.applicantPersonalDataChecked}
                     applicantJobDataChecked={this.state.applicantJobDataChecked}
                     coApplicantsDataChecked={this.state.coApplicantsDataChecked}
                     saving={this.state.saving}
                     emailConfirmation={this.emailConfirmation}
        />;
    }

    render() {
        if (this.state.loading) {
            return <Loader message="Espere unos segundos mientras recuperamos los datos"/>;
        }

        return this.renderContent();
    }
}
