import React, {Component} from 'react';
import CalculatorPage from '../views/CalculatorPage';
import DebtFreeNote from '../views/DebtFreeNote';
import Requirements from '../views/Requirements';
import Privacy from '../views/Privacy';
import TermsAndConditions from "../views/TermsAndConditions";
import BranchOffices from '../views/BranchOffices';
import RealEstate from '../views/RealEstate';
import OurWarranty from '../views/OurWarranty';
import Information from '../views/Information';
import CreateWarrantyLeadWizard from '../views/CreateWarrantyLeadWizard';
import PrequalificationResult from '../views/PrequalificationResult';
import Home from '../views/Home';
import RequestDashboard from '../views/RequestDashboard';
import RequestHistory from '../views/RequestHistory';
import Form from '../views/RequestForm';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {PrivateRoute} from "./PrivateRoute";
import ScrollToTop from "../ScrollToTop";
import Renewal from "../views/Renewal";
import {CalculatorWidget} from "../views/CalculatorWidget";
import SuccessfulEmailConfirmation from "../components/email-confirmation/SuccessfulEmailConfirmation";
import FailedEmailConfirmation from "../components/email-confirmation/FailedEmailConfirmation";
import {ReclaimModal} from "../components/modals/ReclaimModal";
import CreatePaymentLeadWizard from '../views/CreatePaymentLeadWizard';
import InsuranceInformationRequest from "../views/InsuranceInformationRequest";
import ComplementaryInsurance from "../views/ComplementaryInsurance";
import BucketRedirecter from '../views/BucketRedirecter';
import HealthCheck from '../views/HealthCheck';

class Routes extends Component {
    render() {
        return (
            <Router>
                <ScrollToTop>
                    <Route exact path="/" component={Home}/>
                    <Route exact path="/requisitos" component={Requirements}/>
                    <Route exact path="/privacidad" component={Privacy}/>
                    <Route exact path="/terminos" component={TermsAndConditions}/>
                    <Route exact path="/calculadora" component={CalculatorPage}/>
                    <Route exact path="/sucursales" component={BranchOffices}/>
                    <Route exact path="/inmobiliarias" component={RealEstate}/>
                    <Route exact path="/incumplimientos" component={ReclaimModal}/>
                    <Route exact path="/garantia" component={OurWarranty}/>
                    <Route exact path="/seguros-complementarios" component={ComplementaryInsurance}/>
                    <Route exact path="/informacion" component={Information}/>
                    <Route exact path="/inicio" component={CreateWarrantyLeadWizard}/>
                    {/*<Route exact path="/precalificacion" component={StartPrequalificationRequest}/>*/}
                    <Route exact path="/resultado-precalificacion" component={PrequalificationResult}/>
                    {/*<Route exact path="/precalificacion-co" component={PrequalificationAddCoApplicant}/>*/}
                    <Route path="/renovacion/:nro" component={Renewal}/>
                    <Route path="/seguro/:nro" component={InsuranceInformationRequest}/>
                    <PrivateRoute exact path="/dashboard" component={RequestDashboard}/>
                    <PrivateRoute exact path="/historial" component={RequestHistory}/>
                    <PrivateRoute exact path="/formulario" component={Form}/>
                    <Route exact path="/descargar/nota-libre-deuda" component={DebtFreeNote}/>
                    <Route exact path="/widgets/calculadora" component={CalculatorWidget}/>
                    <Route exact path="/confirmacion/email/exito" component={SuccessfulEmailConfirmation}/>
                    <Route exact path="/confirmacion/email/falla" component={FailedEmailConfirmation}/>
                    <Route exact path="/pagar_garantia" component={CreatePaymentLeadWizard}/>

                     <Route exact path="/health-check" component={HealthCheck} />
                    
                    <Route path="/newsletter" component={BucketRedirecter}/>
                </ScrollToTop>
            </Router>
        );
    }
}

export default Routes;