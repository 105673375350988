import React, {Component} from 'react';
import MainNav from "../components/layout/MainNav";
import {Footer} from "../components/layout/Footer";
import {SectionAside} from "../components/elements/SectionAside";
import {ContactModal} from "../components/modals/ContactModal";

export default class ComplementaryInsurance extends Component {

    _buttonText(){
        return "¿Alguna duda sobre seguros? Consultanos"
    }

    _destinationEmail() {
        return "seguros@finaersa.com.ar";
    }

    _contactModalInfo() {
        return <ContactModal class="info-btn" icon="envelope" button="+ Info"
                             showDestinationEmail={this._destinationEmail()}/>;
    }

    render() {
        return (
            <div id="App">
                <MainNav/>
                <main className="sections">
                    <div className="site-content section-content">
                        <section className="section-box" id="complementary-insurance">
                            <header>
                                <h1 className="highlight">Seguros Complementarios</h1>
                            </header>
                            <p className="basic-text">Adicional a nuestra fianza para garantizar tu alquiler, disponemos
                                de distintos tipos de seguros para ofrecerte y que puedas contar con la máxima
                                protección en todo momento.</p>
                            <h2 className="highlight-black">¿A quién está dirigido?</h2>
                            <p className="basic-text">Tanto a Inquilinos como a Propietarios que deseen contratar de
                                manera complementaria los seguros ofrecidos, y que gracias a nuestra alianza con el <b>Grupo
                                    Asegurador La Segunda</b>, cuentan con importantes descuentos para lograr un precio
                                muy competitivo.</p>
                            <h2 className="highlight-black">Productos más solicitados:</h2>
                            <p className="basic-text">La oferta de seguros es sumamente amplia y podemos ofrecer
                                propuestas a medida según sea requerido. Conocé los más pedidos:</p>
                            <ul>
                                <li>
                                    <h3 className="highlight-black">Seguro Contra Incendios</h3>
                                    <p className="basic-text">¿Sabías que los contratos de locación exigen contar con un
                                        seguro contra incendios? Finaer te facilita el trámite y te ofrece acceso a una
                                        tarifa muy atractiva.</p>
                                    {this._contactModalInfo()}
                                </li>
                                <li>
                                    <h3 className="highlight-black">Multicobertura Viviendas y Multicobertura
                                        Comercios</h3>
                                    <p className="basic-text">Tenemos productos con más coberturas para que puedas
                                        contar con la seguridad de proteger tus bienes por menos de lo que crees.</p>
                                    {this._contactModalInfo()}
                                </li>
                            </ul>
                            <p className="basic-text">¿Buscás otro seguro?<span className="highlight"> ¡Consultanos y atendemos tus necesidades!</span>
                            </p>
                        </section>
                        <SectionAside
                            buttonText={this._buttonText()}
                            showDestinationEmail={this._destinationEmail()}/>
                    </div>
                </main>
                <Footer/>
            </div>
        );
    }
}
