import React, {Component} from "react";
import MainNav from "../components/layout/MainNav";
import {Footer} from "../components/layout/Footer";
import {Collapsible, CollapsibleItem} from "react-materialize";
import Link from "react-router-dom/es/Link";
import {InfoAside} from "../components/elements/InfoAside";
import {ReclaimModal} from "../components/modals/ReclaimModal";

class Informacion extends Component {
    render() {
        return (
            <div id="App">
                <MainNav/>
                <main className="sections">
                    <div className="site-content section-content">
                        <section className="section-box" id="informacion">
                            <header>
                                <h1>Información para Propietarios e Inmobiliarias</h1>
                            </header>
                            <p className="basic-text">Contamos con una importante trayectoria en el mercado, tiempo en el cual ganamos el prestigio
                                que tenemos gracias a la dedicación y respeto por nuestros clientes, así como al compromiso
                                en el cumplimiento de nuestras acciones.</p>
                            <p className="basic-text">Hoy, Sistema Finaer cuenta con más de 8.100 Inmobiliarias adheridas que confían en nosotros y
                                nuestra Garantía, así como un gran número de Propietarios que han aceptado garantizar sus
                                propiedades con nuestra Garantía y que dan buena fe de la experiencia con nuestra firma.</p>
                            <Collapsible className="-big" accordion>
                                <CollapsibleItem header='Información para Inmobiliarias' icon='arrow_drop_down'>
                                    <p className="basic-text">Una amplia trayectoria, una extensa red de oficinas y estructura de personal, así como
                                        notables relaciones comerciales, acreditan el por qué Sistema Finaer es la opción más conveniente a la
                                        hora de Garantizar contratos de Alquiler.</p>
                                    <p className="basic-text">La Fianza de Sistema Finaer es un poderoso instrumento concebido para responder ante casos
                                        de incumplimientos de manera rápida, eficaz y confiable; constituyendo la mejor manera de
                                        garantizar tus contratos de locación.</p>
                                    <h2 className="highlight">Ventajas del Sistema Finaer.</h2>
                                    <h3>Agilidad y dinamismo</h3>
                                    <p className="basic-text">La Garantía se otorga en 24 Hs. una vez completada la Solicitud habilitada en nuestra página
                                        web, con los datos y la documentación del Inquilino y de al menos un Co-Solicitante. En caso
                                        de requerir algún refuerzo adicional, le será notificado dentro de ese plazo.</p>
                                    <h3>Rápida resolución de incumplimiento</h3>
                                    <p className="basic-text">Entendemos la importancia de brindar soluciones efectivas ante una situación de
                                        incumplimiento, para ello nuestro Departamento de Legales lo guiará y se encargará de cada caso, sin costos adicionales. Ante incumplimientos, debe comunicarse con este departamento
                                        dentro de los 20 días de ocurrido el incumplimiento llamando al 6842-5100 o completando el <ReclaimModal class="text-link" button="Formulario de Aviso de Incumplimiento"/>.</p>
                                    <h3>Confidencialidad</h3>
                                    <p className="basic-text">Los contratos celebrados son de índole privada.</p>
                                    <h3>¡Potenciá tu cartera!</h3>
                                    <p className="basic-text">Sin costo alguno, podrás adherir tu Inmobiliaria a nuestro sistema y figurar en
                                        nuestro <Link to="/inmobiliarias">Buscador de Inmobiliarias</Link> para aprovechar la gran cantidad de usuarios que visitan
                                        nuestra página diariamente en búsqueda de información.</p>
                                    <h2 className="highlight">¿Cómo proceder frente a incumplimientos?</h2>
                                    <p className="basic-text">Los incumplimientos se notifican a Sistema Finaer en un plazo máximo de 20 días, a través de cualquiera de las siguientes vías: </p>
                                    <h3>Completando el Formulario de Incumplimientos</h3>
                                    <p className="basic-text">Link: <ReclaimModal class="text-link" button="Formulario de Incumplimiento"/></p>
                                    <h3>Enviando un mail al Departamento de Legales (incumplimientos@finaersa.com.ar) indicando:</h3>
                                    <ul className="basic-list">
                                        <li className="basic-list-item">Apellido y Nombre del Propietario</li>
                                        <li className="basic-list-item">Datos de contacto​ del propietario: Tel / Cel/ Mail</li>
                                        <li className="basic-list-item">Apellidos y Nombres de los Inquilinos</li>
                                        <li className="basic-list-item">Domicilio de la Propiedad</li>
                                        <li className="basic-list-item">Inmobiliaria que intervino en el alquiler</li>
                                        <li className="basic-list-item">Fecha de firma del Contrato de Locación</li>
                                        <li className="basic-list-item">Código del Contrato de Fianza (optativo)​</li>
                                    </ul>
                                </CollapsibleItem>
                                <CollapsibleItem header='Información para Propietarios' icon='arrow_drop_down'>
                                    <h2 className="highlight">¿Por qué aceptar Sistema Finaer?</h2>
                                    <p className="basic-text">Respaldados por nuestra amplia trayectoria, el Sistema de Garantías Finaer ha demostrado ser la mejor
                                        Garantía para Alquilar a más de 53.000 Propietarios que ya tuvieron la experiencia de aceptar
                                        nuestra Fianza para garantizar los contratos de locación de sus propiedades.</p>
                                    <h2 className="highlight">¿Por qué la Garantía Finaer le brinda la mejor solución?</h2>
                                    <h3>Garantía con bases sólidas</h3>
                                    <p className="basic-text">Nuestra <span>Garantía</span> se otorga en base a ingresos demostrables y comportamiento crediticio, a diferencia de la garantía propietaria que se obtiene gracias a un vínculo netamente afectivo. <span>Finaer</span> analiza toda la documentación e historial de cada solicitante, reduciendo eficientemente el riesgo de incumplimientos. Además, nuestra <span>Garantía</span> tiene validez hasta la
                                        restitución del inmueble, ¡aun cuando haya vencido la fecha del contrato de locación!</p>
                                    <h3>Alcance</h3>
                                    <p className="basic-text">La Fianza de <span>Finaer</span> garantiza el importe de Alquiler, ABL, Aguas, Expensas y las acciones y
                                        costos legales incluidos.</p>
                                    <h3>Pagos ante Incumplimientos</h3>
                                    <p className="basic-text">Ante la falta de pago, Finaer brinda la tranquilidad al Propietario del cobro de los montos pactados en el contrato de locación cubiertos por nuestra <span>Garantía</span>, hasta la restitución del bien.</p>
                                    <h3>Vigencia</h3>
                                    <p className="basic-text">La vigencia de la <span>Garantía Finaer</span> es hasta la restitución del bien.</p>
                                    <h3>Confidencialidad</h3>
                                    <p className="basic-text">La <span>Garantía Finaer</span> es de índole privada y de carácter confidencial.</p>
                                    <h3>Un compromiso con el cumplimiento</h3>
                                    <p className="basic-text">El Sistema de Garantías Finaer cuenta con una baja tasa de incumplimientos, y aquellos casos
                                        que son tratados a nivel jurídico son resueltos para la conformidad y satisfacción de nuestros
                                        clientes. Para su confianza, puede verificar la información de carácter público en las páginas
                                        del Poder Judicial de la Nación.</p>
                                    <h2 className="highlight">¿Cómo proceder frente a incumplimientos?</h2>
                                    <p className="basic-text">Los incumplimientos se notifican a Sistema Finaer en un plazo máximo de 20 días, a través de cualquiera de las siguientes vías: </p>
                                    <h3>Completando el Formulario de Incumplimientos</h3>
                                    <p className="basic-text">Link: <ReclaimModal class="text-link" button="Formulario de Incumplimiento"/></p>
                                    <h3>Enviando un mail al Departamento de Legales (incumplimientos@finaersa.com.ar) indicando:</h3>
                                    <ul className="basic-list">
                                        <li className="basic-list-item">Apellido y Nombre del Propietario</li>
                                        <li className="basic-list-item">Datos de contacto​ del propietario: Tel / Cel/ Mail</li>
                                        <li className="basic-list-item">Apellidos y Nombres de los Inquilinos</li>
                                        <li className="basic-list-item">Domicilio de la Propiedad</li>
                                        <li className="basic-list-item">Inmobiliaria que intervino en el alquiler</li>
                                        <li className="basic-list-item">Fecha de firma del Contrato de Locación</li>
                                        <li className="basic-list-item">Código del Contrato de Fianza (optativo)​</li>
                                    </ul>
                                </CollapsibleItem>
                            </Collapsible>
                        </section>
                        <InfoAside/>
                    </div>
                </main>
                <Footer/>
            </div>
        );
    }
}

export default Informacion;
