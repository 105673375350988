import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import logo from '../../images/logo-finaer.svg';
import whatsapp from '../../images/icon-whatsapp.svg';
import {SidebarMenu} from "./SidebarMenu";
import Link from "react-router-dom/es/Link";
import LoginModal from "../../components/modals/LoginModal";
import {app} from "../../app/app";


class MainNav extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isTop: true,
            menuOpen: false
        };

        this.handleScroll = this.handleScroll.bind(this);
    }

    handleScroll() {
        const isTop = window.scrollY < 50;
        if (isTop !== this.state.isTop) {
            this.setState({isTop})
        }
    }

    componentDidMount() {
        document.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleStateChange(state) {
        this.setState({menuOpen: state.isOpen})
    }

    toggleMenu() {
        this.setState({menuOpen: !this.state.menuOpen})
    }

    renderLogin() {
        if (app.userIsLoggedIn()) {
            return (
                <Link className="main-nav-item menu-item-logout" to="/dashboard">
                    <FontAwesomeIcon className="icon" icon="user"/>
                    <p>Seguí tu Solicitud</p>
                </Link>);
        } else {
            return <LoginModal/>;
        }
    }

    render() {
        return (
            <header className={this.state.isTop ? 'main-header' : 'main-header scroll'}>
                <nav className={this.state.isTop ? 'main-nav-bar' : 'main-nav-bar scroll'}>
                    <Link className="site-logo" to="/">
                        <img src={logo} className="app-logo" alt="logo"/>
                    </Link>
                    <div className="main-nav-options">
                        <div className="main-nav-item data-only">
                            <a target="_blank" href={app.wppUrl()} className="icon-whatsapp-container" rel="noopener noreferrer">
                                <img src={whatsapp} className="icon-whatsapp" alt="icono whatsapp"/>
                            </a>
                            <a className="phone-number" href="tel:+541168425100">(011) 6842-5100</a>
                        </div>
                        <Link to="/requisitos" className="main-nav-item">
                            <FontAwesomeIcon className="icon" icon="clipboard-list"/>
                            <p>Requisitos</p>
                        </Link>
                        <Link to="/calculadora" className="main-nav-item">
                            <FontAwesomeIcon className="icon" icon="calculator"/>
                            <p>Calculá costos</p>
                        </Link>
                        {this.renderLogin()}
                        <button className="main-ham-button" onClick={() => this.toggleMenu()}>
                            <FontAwesomeIcon className="icon" icon="bars"/>
                        </button>
                    </div>
                </nav>
                <a target="_blank" href={app.wppUrl()} rel="noopener noreferrer">
                    <img src={whatsapp} className="icon-whatsapp-fixed" alt="icono whatsapp"/>
                </a>
                <SidebarMenu
                    customBurgerIcon={false}
                    right
                    isOpen={this.state.menuOpen}
                    onStateChange={(state) => this.handleStateChange(state)}
                />
            </header>
        );
    }
}

export default MainNav;