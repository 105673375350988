import React, {Component} from 'react';
import MainNav from "../components/layout/MainNav";
import {Footer} from "../components/layout/Footer";
import {SectionAside} from "../components/elements/SectionAside";
import Link from "react-router-dom/es/Link";
import {ContactModal} from "../components/modals/ContactModal";

class NuestraGarantia extends Component {

    render() {
        return (
            <div id="App">
                <MainNav/>
                <main className="sections">
                    <div className="site-content section-content">
                        <section className="section-box" id="garantia">
                            <header>
                                <h1 className="highlight">Nuestra Garantía</h1>
                            </header>
                            <p className="basic-text">El Sistema de <span>Garantías Finaer</span> permite a los Inquilinos acceder a las exigencias de los contratos de alquiler en forma ágil, rápida y sencilla. Con la <span>Garantía Finaer</span> los Inquilinos evitan presentar la tradicional garantía propietaria.</p>
                            <p className="basic-text">Nuestro sistema consta de una Fianza que garantiza al Propietario el cobro del monto pactado en el contrato de locación hasta la restitución total del bien.</p>
                            <p className="basic-text">Para un Inquilino, obtener una <span>Garantía Finaer</span> es un proceso muy sencillo:</p>
                            <ul className="basic-list">
                                <li className="basic-list-item">Completá la Solicitud en nuestra web.</li>
                                <li className="basic-list-item">Adjuntá la documentación solicitada.</li>
                                <li className="basic-list-item">Te contactaremos de inmediato y guiaremos en todo momento que lo necesites.</li>
                            </ul>
                            <p className="basic-text">Dentro de las 24 Hs. de completados los datos y la documentación requerida, se obtiene la resolución de la Garantía Finaer.</p>
                            <p className="basic-text"><span>Sin costos de gestión.</span> El Inquilino sólo abonará los honorarios del servicio por única vez
                                cuando se realice la firma del Contrato de Fianza. El costo del servicio puede obtenerse utilizando el <Link to="/calculadora">calculador automático</Link> disponible en nuestra web. Además, ofrecemos formas de pago y financiación para nuestros clientes.</p>
                            <p className="basic-text">Nuestro compromiso: la calidez humana y la dedicación en nuestro servicio, nos distinguen en el mercado.</p>
                            <h2 className="highlight">Alcance</h2>
                            <p className="basic-text">La Fianza de <span>Sistema Finaer</span> garantiza el importe de Alquiler, ABL, Aguas, Expensas y las acciones y costos legales incluidos.</p>
                            <h2 className="highlight">Ventajas</h2>
                            <p className="basic-text"><span>Al alcance todos</span>. Los Inquilinos pueden acceder a la Garantía Finaer demostrando sus ingresos, o el de las personas que lo avalen.</p>
                            <p className="basic-text"><span>Más oportunidades con un solo click.</span> Para quienes están en la búsqueda de una vivienda para alquilar, podrán dirigirse a nuestro <Link to="/inmobiliarias">Buscador de Inmobiliarias</Link> para conocer la extensa red de Profesionales Inmobiliarios que confían y trabajan con la Garantía Finaer.</p>
                            <p className="basic-text"><span>Respuesta inmediata.</span> Una vez completados los datos y la documentación requerida, se obtiene la resolución de la Garantía dentro de las 24 Hs. hábiles.</p>
                            <p className="basic-text"><span>Sin límites de edad ni montos.</span> todas las personas mayores de 18 años pueden solicitar una <span>Garantía Finaer.</span> Tampoco hay límites en el monto del bien a alquilar.</p>
                            <p className="basic-text"><span>Sin costos de gestión.</span> Iniciar una Garantía Finaer no tiene asociado gastos de ninguna índole. Únicamente deberá abonar el servicio al momento de realizar la firma de la Fianza.</p>
                            <p className="basic-text"><span>Amplia financiación.</span> El costo del servicio se abona por única vez cuando se firma el contrato de Fianza. Nuestros clientes pueden elegir distintos planes de pago a su conveniencia.</p>
                            <p className="basic-text">¿Dudas? <ContactModal class="text-link" button="Consultanos"/>.</p>
                            <h2 className="highlight">Requisitos</h2>
                            <p className="basic-text">Para conocer los requisitos, hacé <Link to="/requisitos">click aquí</Link>.</p>
                        </section>
                        <SectionAside/>
                    </div>
                </main>
                <Footer/>
            </div>
        );
    }
}

export default NuestraGarantia;
