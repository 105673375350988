import request from "superagent";
import 'whatwg-fetch';
import {app} from "../app/app";

export class Api {
    getBranchOffices() {
        throw new Error("You have to implement the method");
    }

    getRealEstate(requestData) {
        throw new Error("You have to implement the method");
    }

    getAllRealEstate() {
        throw new Error("You have to implement the method");
    }

    getProvincesAndCities() {
        throw new Error("You have to implement the method");
    }

    getCompanyAgreements() {
        throw new Error("You have to implement the method");
    }

    newReclaim(reclaimData) {
        throw new Error("You have to implement the method");
    }

    newRealState(realStateData) {
        throw new Error("You have to implement the method");
    }

    newWarrantyApplication(applicationData) {
        throw new Error("You have to implement the method");
    }

    calculateRentalCosts(rentalData) {
        throw new Error("You have to implement the method");
    }

    sendQuestion(contactData) {
        throw new Error("You have to implement the method");
    }

    sendRegret(contactData) {
        throw new Error("You have to implement the method");
    }

    login(requestData) {
        throw new Error("You have to implement the method");
    }

    getRequestDetails(warrantyApplicationNumber) {
        throw new Error("You have to implement the method");
    }

    getParticipantsData(warrantyApplicationNumber) {
        throw new Error("You have to implement the method");
    }

    getParticipantsPersonalAndJobDataChecked(warrantyApplicationNumber) {
        throw new Error("You have to implement the method");
    }

    getPropertyData(warrantyApplicationNumber) {
        throw new Error("You have to implement the method");
    }

    editApplicant(applicantData) {
        throw new Error("You have to implement the method");
    }

    editCoApplicant(CoApplicantData) {
        throw new Error("You have to implement the method");
    }

    addCoApplicant(warrantyApplicationNumber) {
        throw new Error("You have to implement the method");
    }

    createWarrantyPrequalification(warrantyPrequalificationData) {
        throw new Error("You have to implement the method");
    }

    addCoApplicantForPrequalification(coApplicantData, warrantyPrequalificationNumber) {
        throw new Error("You have to implement the method");
    }

    getWarrantyPaymentDetails(warrantyApplicationNumber) {
        throw new Error("You have to implement the method");
    }

    getWarrantyPaymentOptionsDetails(warrantyApplicationNumber) {
        throw new Error("You have to implement the method");
    }

    settlePaymentMode(paymentData) {
        throw new Error("You have to implement the method");
    }

    getInformationForNewWarrantyPayment(paymentData) {
        throw new Error("You have to implement the method");
    }

    getMercadoPagoPreference(warrantyNumber, paymentData) {
        throw new Error("You have to implement the method");
    }

    settleMercadoPagoPayment(warrantyNumber, paymentData) {
        throw new Error("You have to implement the method");
    }

    mercadoPagoCyberWeekDiscount(){
        throw new Error("You have to implement the method");
    }
}

export class RemoteApi extends Api {
    constructor(url) {
        super();
        this.url = url;

        this.editApplicant = this.editApplicant.bind(this);
        this.editCoApplicant = this.editCoApplicant.bind(this);
        this.addApplicantDocuments = this.addApplicantDocuments.bind(this);
        this.addCoApplicantDocuments = this.addCoApplicantDocuments.bind(this);
    }

    getBranchOffices() {
        return this.call('oficinas/');
    }

    getRealEstate(requestData) {
        requestData = requestData || {};
        return this.call('inmobiliarias/', 'GET', requestData);
    }

    getAllRealEstate() {
        return this.call('inmobiliarias/todas/', 'GET');
    }

    getProvincesAndCities() {
        return this.call('regiones/');
    }

    getCompanyAgreements() {
        return this.call('convenios/');
    }

    newRealState(realStateData) {
        return this.call('adherir-inmobiliaria/', 'POST', realStateData, 'multipart/form-data');
    }

    newReclaim(reclaimData) {
        return this.call('agregar-incumplimiento/', 'POST', reclaimData);
    }

    newWarrantyApplication(applicationData) {
        return this.call('nueva-solicitud/', 'POST', applicationData);
    }

    getLeadFromPrequalification(prequalificationData) {
        return this.call('obtener-prospecto-de-precalificacion/', 'POST', prequalificationData);
    }

    newLead(leadData) {
        return this.call('nuevo-prospecto/', 'POST', leadData);
    }

    editLead(leadId, leadData) {
        return this.call('prospecto/' + leadId + '/editar/', 'POST', leadData);
    }

    newWarrantyFromLead(leadData) {
        return this.call('convertir-prospecto/', 'POST', leadData);
    }

    calculateRentalCosts(rentalData) {
        return this.call('calcular-costo-del-servicio/', 'POST', rentalData);
    }

    requestEmailShipping(rentalAndContactData) {
        return this.call('notificar-costo-del-servicio/', 'POST', rentalAndContactData);
    }

    sendQuestion(contactData) {
        return this.call('enviar-consulta/', 'POST', contactData);
    }

    sendRegret(regretData) {
        return this.call('nuevo-arrepentimiento/', 'POST', regretData);
    }

    login(requestData) {
        return this.call('login/', 'POST', requestData);
    }

    getPropertyData(warrantyApplicationNumber) {
        return this.call('inmueble/' + warrantyApplicationNumber + '/');
    }

    getRequestDetails(warrantyApplicationNumber) {
        return this.call('solicitud/' + warrantyApplicationNumber + '/detalle/');
    }

    getParticipantsData(warrantyApplicationNumber) {
        return this.call('solicitud/' + warrantyApplicationNumber + '/participantes/');
    }

    getParticipantsPersonalAndJobDataChecked(warrantyApplicationNumber) {
        return this.call('solicitud/' + warrantyApplicationNumber + '/datos-personales-laborales/')
    }

    editApplicant(warrantyApplicationNumber, applicantData) {
        return this.call('solicitante/' + warrantyApplicationNumber + '/editar/', 'POST', applicantData);
    }

    editProperty(warrantyApplicationNumber, propertyData) {
        return this.call('inmueble/' + warrantyApplicationNumber + '/editar/', 'POST', propertyData);
    }

    editCompanyAgreement(warrantyApplicationNumber, companyAgreementData) {
        return this.call('solicitud/' + warrantyApplicationNumber + '/editar-convenio/', 'POST', companyAgreementData);
    }

    editCoApplicant(warrantyApplicationNumber, CoApplicantData) {
        return this.call('cosolicitante/' + warrantyApplicationNumber + '/editar/', 'POST', CoApplicantData);
    }

    addCoApplicant(warrantyApplicationNumber) {
        return this.call('cosolicitante/' + warrantyApplicationNumber + '/nuevo/', 'POST');
    }

    addApplicantDocuments(warrantyApplicationNumber, participantId, files) {
        let url = this.url + 'solicitante/' + warrantyApplicationNumber + '/nuevo-comprobante/';

        return this.uploadFiles(url, files, participantId);
    }

    addCoApplicantDocuments(warrantyApplicationNumber, participantId, files) {
        let url = this.url + 'cosolicitante/' + warrantyApplicationNumber + '/nuevo-comprobante/';

        return this.uploadFiles(url, files, participantId);
    }

    getRequestHistory(warrantyApplicationNumber) {
        return this.call('solicitante/' + warrantyApplicationNumber + '/historial/');
    }

    createWarrantyPrequalification(warrantyPrequalificationData) {
        return this.call('pedido-precalificacion/', 'POST', warrantyPrequalificationData);
    }

    addCoApplicantForPrequalification(coApplicantData, warrantyPrequalificationNumber) {
        return this.call('pedido-precalificacion/' + warrantyPrequalificationNumber + '/nuevo-cosolicitante',
            'POST', coApplicantData);
    }

    getRenewal(requestData) {
        requestData = requestData || {};
        return this.call('renovacion/', 'GET', requestData);
    }

    sendEmailConfirmation(warrantyApplicationNumber, requestData) {
        requestData = requestData || {};
        return this.call(
            'solicitud/' + warrantyApplicationNumber + '/confirmacion-email/', 'POST', requestData);
    }

    sendEmailInsuranceInformationRequested(requestData) {
        return this.call(
            'informacion-de-seguros/' , 'POST', requestData);
    }

    call(resourceUrl, method = 'GET', body = undefined, contentType = 'application/json') {
        let headers = Object.assign(this.contentTypeHeaders(), this.authorizationHeaders());
        let encoder = this.encoderFor(contentType);
        let requestOptions = {method: method, headers: headers};
        let getParams = '';

        if (method === 'GET') {
            getParams = this.getParameters(body);
        } else {
            Object.assign(headers, encoder.headers());
            Object.assign(requestOptions, {body: encoder.encode(body)});
        }

        return fetch(this.url + resourceUrl + getParams, requestOptions).then((response) => {
            return response.json();
        });
    }

    uploadFiles(url, files, participantId) {
        const newRequest = request.post(url);
        newRequest.set('Authorization', this.authorizationHeaders()['Authorization']);
        files.forEach(file => {
            newRequest.attach(file.name, file);
        });

        return newRequest.field('id', participantId.toString());
    }

    contentTypeHeaders() {
        return {'Accept': 'application/json'};
    }

    authorizationHeaders() {
        if(app.userIsLoggedIn()) {
            return {'Authorization': 'Token ' + app.loggedInUser().authToken()};
        }

        return {}
    }

    encoderFor(contentType) {
        let encoders = [new JsonEncoder(), new MultiPartEncoder()];
        return encoders.find(enc => enc.accepts(contentType));
    }

    getParameters(parameters) {
        if(!parameters || Object.keys(parameters).length === 0) {
            return ''
        }
        const keyValuePairs = [];
        for (const key in parameters) {
            keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key]));
        }
        return '?' + keyValuePairs.join('&');
    }

    getWarrantyPaymentDetails(warrantyApplicationNumber) {
        return this.call('resumen-de-pago/' + warrantyApplicationNumber);
    }

    getWarrantyPaymentOptionsDetails(warrantyApplicationNumber) {
        return this.call('politica-de-pago/' + warrantyApplicationNumber);
    }

    settlePaymentMode(paymentData) {
        return this.call('asentar-modo-de-pago-solicitud/', 'POST', paymentData);
    }

    getInformationForNewWarrantyPayment(paymentData) {
        const warrantyNumber = paymentData['nro_solicitud']
        return this.call('consultar-plan-de-pago-a-crear/' + warrantyNumber + '/', 'GET', paymentData);
    }

    getMercadoPagoPreference(warrantyNumber, paymentData) {
        return this.call('solicitud/' + warrantyNumber + '/realizar-pago-via-mercadopago/', 'POST', paymentData);
    }

    settleMercadoPagoPayment(warrantyNumber, paymentData) {
        return this.call('solicitud/' + warrantyNumber + '/realizar-pago-via-mercadopago/', 'POST', paymentData);
    }

    mercadoPagoCyberWeekDiscount() {
        return this.call('porcentaje-descuento-mercadopago/');
    }
}

export class FakeApi extends Api {
    constructor(props) {
        super(props);

        this.offices = {
            'Santa Fe': [
                {
                    nombre: "Santa Fe",
                    localidad: "Rosario",
                    domicilio: "La calle 123",
                    latitud: -32.956964,
                    longitud: -60.632113,
                    telefono: 12345655,
                    email: "oficina@rosario.com"
                },

            ],
            'Buenos Aires (Zona Sur)': [
                {
                    nombre: "Quilmes",
                    localidad: "Quilmes",
                    domicilio: "La otra calle 456",
                    latitud: -32.956964,
                    longitud: -60.632113,
                    telefono: 45455665,
                    email: "oficina@quilmes.com"
                },

            ]
        };

        this.realEstate = [
            {
                id: 1,
                nombre: "Inmobiliaria Gonzalez",
                email: "inmobiliaria@gonzalez.com",
                web: "inmobiliariagonzalez.com.ar",
                domicilio: "Una calle 1234",
                telefono: 12345655,
                provincias: ["CAP"],
                localidades: ["Villa Crespo", "Palermo", "Almagro"],
                url_logo: "https://s3.amazonaws.com/finaer/media/uploads/logos_inmobiliarias/corradini.jpg",
            },
            {
                id: 2,
                nombre: "Inmobiliaria Perez",
                email: "inmobiliaria@perez.com",
                web: "perezinmoehijos.com.ar",
                domicilio: "La otra calle 33",
                telefono: 77448989,
                provincias: ["GBN"],
                localidades: ["Olivos", "San Isidro", "Vicente Lopez"],
                url_logo: "https://s3.amazonaws.com/finaer/media/uploads/logos_inmobiliarias/Sin_t%C3%ADtulo_wUGx2mS.jpg",
            },
            {
                id: 3,
                nombre: "Inmobiliaria Martinez",
                email: "inmobiliaria@martinez.com",
                web: "martinez.com.ar",
                domicilio: "Una calle 33",
                telefono: 1234567,
                provincias: ["BUE"],
                localidades: ["Azul"],
                url_logo: "https://s3.amazonaws.com/finaer/media/uploads/logos_inmobiliarias/A.Romero_1.jpg",
            },
            {
                id: 4,
                nombre: "Inmobiliaria Sanchez",
                email: "inmobiliaria@sanchez.com",
                web: "sanchez.com.ar",
                domicilio: "La otra calle 33",
                telefono: 77448989,
                provincias: ["GBS"],
                localidades: ["Banfield"],
                url_logo: "https://s3.amazonaws.com/finaer/media/uploads/logos_inmobiliarias/A._Pacio.jpg",
            },
            {
                id: 5,
                nombre: "Inmobiliaria Alvarez",
                email: "inmobiliaria@alvarez.com",
                web: "alvarez.com.ar",
                domicilio: "La otra calle 33",
                telefono: 77409989,
                provincias: ["CAP"],
                localidades: ["Villa Crespo"],
                url_logo: "https://s3.amazonaws.com/finaer/media/uploads/logos_inmobiliarias/a_y_n.JPG",
            },
            {
                id: 6,
                nombre: "La Inmobiliaria de La familia",
                email: "inmobiliaria@familia.com",
                web: "lainmofamiliar.com.ar",
                domicilio: "9 de Julio 1920",
                telefono: 11112222,
                provincias: ["GBS"],
                localidades: ["Banfield"],
                url_logo: "https://s3.amazonaws.com/finaer/media/uploads/logos_inmobiliarias/as_consultora.JPG",
            },
            {
                id: 7,
                nombre: "Guerrero propiedas",
                email: "guerrero@propiedas.com",
                web: "propguerrero.com.ar",
                domicilio: "Belgrano 330",
                telefono: 120912322,
                provincias: ["SFE"],
                localidades: ["Rosario"],
                url_logo: "https://s3.amazonaws.com/finaer/media/uploads/logos_inmobiliarias/A.Saravi.jpg",
            },
            {
                id: 8,
                nombre: "El depto feliz",
                email: "eldepot@feliz.com",
                web: "eldeptofeliz.com.ar",
                domicilio: "Aguero 89",
                telefono: 77448989,
                provincias: ["GBN"],
                localidades: ["Olivos", "Vicente Lopez"],
                url_logo: "https://s3.amazonaws.com/finaer/media/uploads/logos_inmobiliarias/24679_104303269611476_6568099_n.jpg",
            },
            {
                id: 9,
                nombre: "Rimacs",
                email: "rimacs@propiedades.com",
                web: "riMacs.com.ar",
                domicilio: "Mitre 1234",
                telefono: 11122333,
                provincias: ["CAP"],
                localidades: ["Belgrano"],
                url_logo: "https://s3.amazonaws.com/finaer/media/uploads/logos_inmobiliarias/Abate.jpg",
            },
            {
                id: 10,
                nombre: "Miglio",
                email: "miglio@miglio.com",
                web: "miglio.com.ar",
                domicilio: "Medrano 2200",
                telefono: 12312244,
                provincias: ["GBS"],
                localidades: ["Ezeiza"],
                url_logo: "https://s3.amazonaws.com/finaer/media/uploads/logos_inmobiliarias/Aber_Prop.jpg",
            }
        ];

        this.provinces = {
            'GBN': 'Buenos Aires (Zona Norte)',
            'GBS': 'Buenos Aires (Zona Sur)',
            'BUE': 'Buenos Aires (Provincia)',
            'CAP': 'Ciudad Autonoma de Buenos Aires'
        };

        this.cities = {
            'GBN': ["Olivos", "San Isidro", "Vicente Lopez"],
            'GBS': ["Banfield"],
            'CAP': ["Villa Crespo", "Palermo", "Almagro"],
            'BUE': ["Azul", "Cañuelas"]
        };

        this.requestDetails = {
            'estado': 'INCOMPLETA',
            'porcentaje_de_avance': 45,
            'nro': 'ASDAASD',
            'solicitante': 'Juan Perez',
            'oficina': {
                'nombre': 'CASA CENTRAL',
                'domicilio': 'Av. Corrientes 3360, 6º',
                'ubicacion': 'Almagro, CABA',
                'telefono': '6842-5100',
                'email': 'info@finaersa.com.ar',
            }
        };

        this.applicant = {
            nombre: 'Un nombre',
            apellido: 'Un apellido',
            tipo_doc: '',
            num_doc: '',
            celular_codigo: '',
            celular_numero: '',
            email: '',
            fecha_nacimiento: '',
            sexo: '',
            cuit: '',
            domicilio_calle: '',
            domicilio_numero: '',
            domicilio_piso: '',
            domicilio_departamento: '',
            domicilio_provincia: '',
            domicilio_ciudad: '',
            empleado_tipo: '',
            empresa_ocu_actual: '',
            cuit_empresa_ocu_actual: '',
            domicilio_calle_ocu_actual: '',
            domicilio_numero_ocu_actual: '',
            domicilio_piso_ocu_actual: '',
            domicilio_departamento_ocu_actual: '',
            localidad_ocu_actual: '',
            domicilio_provincia_ocu_actual: '',
            domicilio_ciudad_ocu_actual: '',
            telefono_codigo_ocu_actual: '',
            telefono_numero_ocu_actual: '',
            fecha_ingreso_ocu_actual: '',
            ingresos_monto: '',
            ocupa_inmueble: ''
        };

        this.coApplicants = [this.applicant];

        this.property = {
            'domicilio_inmueble_calle': '',
            'domicilio_inmueble_numero': '',
            'domicilio_inmueble_piso': '',
            'domicilio_inmueble_departamento': '',
            'domicilio_inmueble_localidad': '',
            'domicilio_inmueble_provincia': '',
            'tipo_de_alquiler': '',
            'duracion_alquiler': '',
            'periodo_de_alquiler': '',
            'alquiler_pactado_periodo_1': '',
            'alquiler_pactado_periodo_2': '',
            'alquiler_pactado_periodo_3': '',
            'alquiler_pactado_periodo_4': '',
            'alquiler_pactado_periodo_5': '',
            'alquiler_pactado_periodo_6': '',
            'alquiler_pactado_periodo_7': '',
            'alquiler_pactado_periodo_8': '',
            'alquiler_pactado_periodo_9': '',
            'expensas_pactadas': '',
            'inmobiliaria_id': '',
        }
    }

    getBranchOffices() {
        let self = this;
        return new Promise(function (resolve, reject) {
            setTimeout(function(){
                resolve(new FakeResponse({'oficinas': self.offices}).successResponse());
            }, 250);
        });
    }

    getAllRealEstate() {
        let self = this;
        return new Promise(function (resolve, reject) {
            setTimeout(function(){
                resolve(new FakeResponse({'inmobiliarias': self.realEstate}).successResponse());
            }, 250);
        });
    }

    getRealEstate(requestData) {
        let self = this;
        return new Promise(function (resolve, reject) {
            setTimeout(function(){
                resolve(new FakeResponse({'inmobiliarias': self.realEstate}).successResponse());
            }, 250);
        });
    }

    getProvincesAndCities() {
        let self = this;
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(new FakeResponse({'provincias': self.provinces, 'localidades': self.cities}).successResponse());
            }, 250);
        });
    }

    getCompanyAgreements() {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(new FakeResponse({'convenios': []}).successResponse());
            }, 250);
        });
    }

    newReclaim(reclaimData) {
        return new Promise(function (resolve, reject) {
            setTimeout(function(){
                resolve(new FakeResponse({}).successResponse());
            }, 250);
        });
    }

    newWarrantyApplication(applicationData) {
        return new Promise(function (resolve, reject) {
            setTimeout(function(){
                resolve(new FakeResponse({}).successResponse());
            }, 250);
        });
    }

    newRealState(realStateData) {
        return new Promise(function (resolve, reject) {
            setTimeout(function(){
                resolve(new FakeResponse({}).successResponse());
            }, 250);
        });
    }

    calculateRentalCosts(rentalData) {
        return new Promise(function (resolve, reject) {
            setTimeout(function(){
                resolve(new FakeResponse({'monto': 5000}).successResponse());
            }, 250);
        });
    }

    sendQuestion(contactData) {
        return new Promise(function (resolve, reject) {
            setTimeout(function() {
                resolve(new FakeResponse({}).successResponse());
            }, 250);
        });
    }

    login(requestData) {
        let errors = {};
        if (!requestData['tipo_doc']) {
            errors['tipo_doc'] = ['Debe completar este campo']
        }

        if (!requestData['num_doc']) {
            errors['num_doc'] = ['Debe completar este campo']
        }

        if (!requestData['codigo_solicitud']) {
            errors['codigo_solicitud'] = ['Debe completar este campo'];
        }

        return new Promise(function (resolve, reject) {
            setTimeout(function() {
                if (Object.keys(errors).length > 0) {
                    resolve(new FakeResponse(errors).errorResponse());
                } else {
                    resolve(new FakeResponse({}).successResponse());
                }
            }, 250);
        });
    }

    getRequestDetails(warrantyApplicationNumber) {
        let self = this;
        return new Promise(function (resolve, reject) {
            setTimeout(function() {
                resolve(new FakeResponse({'solicitud': [self.requestDetails]}).successResponse());
            }, 250);
        });
    }

    getParticipantsData(warrantyApplicationNumber) {
        let self = this;
        return new Promise(function (resolve, reject) {
            setTimeout(function() {
                resolve(new FakeResponse({
                    'solicitante': self.applicant,
                    'cosolicitantes': self.coApplicants
                }).successResponse());
            }, 250);
        });
    }

    getPropertyData(warrantyApplicationNumber) {
        let self = this;
        return new Promise(function (resolve, reject) {
            setTimeout(function() {
                resolve(new FakeResponse({
                    'inmueble': self.property
                }).successResponse());
            }, 250);
        });
    }

    editApplicant(warrantyApplicationNumber, applicantData) {
        return new Promise(function (resolve, reject) {
            setTimeout(function(){
                resolve(new FakeResponse({'resultado': "OK"}).response());
            }, 250);
        });
    }

    editCoApplicant(warrantyApplicationNumber, CoApplicantData) {
        return new Promise(function (resolve, reject) {
            setTimeout(function(){
                resolve(new FakeResponse({'resultado': "OK"}).response());
            }, 250);
        });
    }

    addCoApplicant(warrantyApplicationNumber) {
        return new Promise(function (resolve, reject) {
            setTimeout(function(){
                resolve(new FakeResponse({'resultado': "OK", 'id': Math.ceil(Math.random() * 100)}).response());
            }, 250);
        });
    }

    getRequestHistory(warrantyApplicationNumber) {
        let self = this;
        return new Promise(function (resolve, reject) {
            setTimeout(function() {
                resolve(new FakeResponse({'solicitudes': [self.requestDetails]}).successResponse());
            }, 250);
        });
    }

    createWarrantyPrequalification(warrantyPrequalificationData) {
        return new Promise(function (resolve, reject) {
            setTimeout(function() {
                resolve(new FakeResponse({'resultado': 'ERROR'}).response());
            }, 250);
        });
    }

    addCoApplicantForPrequalification(coApplicantData, warrantyPrequalificationNumber) {
        return new Promise(function (resolve, reject) {
            setTimeout(function() {
                resolve(new FakeResponse({
                    'resultado': 'OK',
                    'codigo': 2,
                },).response());
            }, 250);
        });
    }
}

class FakeResponse {
    constructor(data) {
        this._data = data;
    }

    response() {
        return this._data;
    }

    successResponse() {
        let response = {
            errors: {},
            result: {}
        };

        Object.keys(this._data).map((key) => {
            return response['result'][key] = this._data[key];
        });

        return response;
    }

    errorResponse() {
        let response = {
            errors: {},
            result: {}
        };

        Object.keys(this._data).map((key) => {
            return response['errors'][key] = this._data[key];
        });

        return response;
    }
}

class Encoder {
    accepts(mimeType) {
        throw new Error("You have to implement the method");
    }

    headers() {
        throw new Error("You have to implement the method");
    }

    encode(requestBody) {
        throw new Error("You have to implement the method");
    }
}

class MultiPartEncoder extends Encoder {
    accepts(mimeType) {
        return mimeType === 'multipart/form-data'
    }

    headers() {
        return {}
    }

    encode(requestBody) {
        let formData = new FormData();

        for (let field in requestBody) {
            let value = requestBody[field];

            if (value !== undefined) {
                formData.append(field, value);
            }
        }

        return formData;
    }
}

class JsonEncoder extends Encoder {
    accepts(mimeType) {
        return mimeType === 'application/json'
    }

    headers() {
        return {'Content-Type': 'application/json'}
    }

    encode(requestBody) {
        return JSON.stringify(requestBody);
    }
}