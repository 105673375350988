import React, {Component} from 'react';
import {Collapsible, CollapsibleItem} from 'react-materialize'

class RequirementsAccordion extends Component {
    render() {
        return (
            <section>
                <h2>Documentación requerida para:</h2>
                <Collapsible accordion>
                    <CollapsibleItem header='EMPLEADOS EN RELACIÓN DE DEPENDENCIA:' icon='arrow_drop_down'>
                        <ul className="basic-list">
                            <li className="basic-list-item">Último recibo de sueldo con haberes mensuales, o las 2 últimas quincenas consecutivas.</li>
                            <li className="basic-list-item">DNI (frente y dorso).</li>
                            <li className="basic-list-item">Antigüedad mínima 3 meses.</li>
                        </ul>
                        <p className="basic-text">Para optar por una Solicitud de Garantía a sola Firma,
                            deberá cumplir con 6 meses de antigüedad laboral demostrable y tener ingresos
                            que dupliquen el monto de alquiler más expensas.</p>
                    </CollapsibleItem>
                    <CollapsibleItem header='MONOTRIBUTISTAS:' icon='arrow_drop_down'>
                        <ul className="basic-list">
                            <li className="basic-list-item">6 últimas facturas emitidas, o las de mayor valor del último trimestre.</li>
                            <li className="basic-list-item">3 últimos pagos de Monotributo.</li>
                            <li className="basic-list-item">Constancia de Categoría o Inscripción AFIP.</li>
                            <li className="basic-list-item">DNI (frente y dorso).</li>
                            <li className="basic-list-item">Antigüedad mínima 1 año.</li>
                        </ul>
                        <p className="basic-text">Para optar por una Solicitud de Garantía a sola Firma,
                            deberá cumplir con 2 años de antigüedad y tener ingresos que quintupliquen
                            el monto de alquiler más expensas.</p>
                    </CollapsibleItem>
                    <CollapsibleItem header='RESPONSABLES INSCRIPTOS Y/O AUTÓNOMOS:' icon='arrow_drop_down'>
                        <ul className="basic-list">
                            <li className="basic-text">Persona Física</li>
                            <li className="basic-list-item">Constancia de Inscripción.</li>
                            <li className="basic-list-item">DNI (frente y dorso).</li>
                            <li className="basic-list-item">DDJJ de Ganancias de personas físicas (Form 711).</li>
                            <li className="basic-list-item">3 últimas presentaciones de IVA (Form 2002 o Form 731).</li>
                            <li className="basic-list-item">6 facturas emitidas actualizadas dentro del último trimestre.</li>
                            <li className="basic-list-item">3 últimos pagos de autónomos (en caso de ser autónomo).</li>
                            <li className="basic-list-item">Antigüedad mínima 1 año.</li>
                        </ul>
                        <p className="basic-text">Para optar por una Solicitud de Garantía a sola Firma,
                            deberá cumplir con 2 años de antigüedad y tener ingresos que quintupliquen
                            el monto de alquiler más expensas.</p>

                        <ul className="basic-list">
                            <li className="basic-text">Persona Jurídica</li>
                            <li className="basic-list-item">Contrato de Sociedad, estatuto y poder.</li>
                            <li className="basic-list-item">Constancia de Inscripción.</li>
                            <li className="basic-list-item">DDJJ de Ganancias de personas jurídicas (Form 713).</li>
                            <li className="basic-list-item">3 últimas presentaciones de IVA (Form 2002 Form 731).</li>
                            <li className="basic-list-item">6 facturas emitidas actualizadas dentro del último trimestre.</li>
                        </ul>
                        <p className="basic-text"><span>IMPORTANTE: </span>En casos especiales se podrán solicitar requisitos adicionales.</p>
                    </CollapsibleItem>
                </Collapsible>
            </section>
        );
    }
}

export default RequirementsAccordion;